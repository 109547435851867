<template>
  <div class="home-box">
    <div class="m-title3">
      <!-- PolySmartChain 的开始 -->
      <div class="t">{{ $t('home.box3T') }}</div>
    </div>
    <div class="boxBox" v-if="false">
      <div class="box">
        <div
          class="m-item1"
          v-for="(item, index) in $t('home.box3ItemList')"
          :key="index"
        >
          <div class="img">
            <img
              :src="require(`../../assets/images/v4/home/box3${index + 1}.png`)"
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="p">{{ item.text }}</div>
          <div class="more">
            <a href="javascript:;">
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use></svg
            ></a>
          </div>
        </div>
      </div>
    </div>

    <el-carousel :interval="40000" type="card" height="(400 / 3.75vw)">
      <el-carousel-item
        class="m-item1"
        v-for="(item, index) in $t('home.box3ItemList')"
        :key="index"
      >
        <div class="img">
          <img
            :src="require(`../../assets/images/v4/home/box3${index + 1}.png`)"
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>

        <div class="p">{{ item.text }}</div>
        <div class="more" @click="uti.goPath(store.link.home.box3List[index])">
          {{ item.ft }}
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-arrowRight"></use>
          </svg>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="dianBox" v-if="false">
      <div class="dian act"></div>
      <div class="dian"></div>
      <div class="dian"></div>
      <div class="dian"></div>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.el-carousel {
  margin-top: (20 / 3.75vw);
}
.el-carousel__item--card {
  position: absolute;
  width: 50%;
}
/deep/.is-active.el-carousel__item--card {
  // transform: none !important;
  transform: translateX((71.25 / 3.75vw)) !important;
  // margin-left: 19%;
  width: 62%;
}
/deep/ .el-carousel__indicators {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-carousel__button {
  width: (8 / 3.75vw);
  height: (8 / 3.75vw);
  border-radius: 50%;
  border: 1px solid #fff;
  background: none;
}
/deep/ .el-carousel__mask {
  background-color: #000;
}
/deep/ .is-active .el-carousel__button {
  background: #fff;
}
.home-box {
  width: 100vw;
  background-image: url('../../assets/images/v4/home/m/box3Bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: (22 / 3.75vw);
  padding-bottom: (12 / 3.75vw);
  box-sizing: border-box;
  .boxBox {
    width: 100vw;
    overflow: auto;
    .box {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      margin-top: (10 / 3.75vw);
      height: 300px;

      // .m-item1 {
      //   position: absolute;
      //   width: (233.33 / 3.75vw);
      //   height: 300px;
      //   padding: 0 10px 50px;
      // }
      .item:hover {
        margin-top: -65px;
      }
    }
  }
  .t {
    margin-top: 0;
  }
  .dianBox {
    height: (10 / 3.75vw);
    // background-color: #fff;
    margin-top: (25 / 3.75vw);
    margin: 0 auto;
    width: (78 / 3.75vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dian {
      width: (6 / 3.75vw);
      height: (8 / 3.75vw);
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .act {
      width: (10 / 3.75vw);
      height: (10 / 3.75vw);
      background-color: #fff;
    }
  }
}
</style>
