<template>
  <div class="home">
    <home-box-1></home-box-1>
    <home-box-2></home-box-2>
    <home-box-3></home-box-3>
    <home-box-4></home-box-4>
    <home-box-5></home-box-5>
    <home-box-6></home-box-6>
    <home-box-8></home-box-8>
  </div>
</template>

<script>
import HomeBox1 from "./home-box1.vue";
import HomeBox2 from "./home-box2.vue";
import HomeBox3 from "./home-box3.vue";
import HomeBox4 from "./home-box4.vue";
import HomeBox5 from "./home-box5.vue";
import HomeBox6 from "./home-box6.vue";
import HomeBox8 from "./home-box8.vue";

export default {
  components: {
    HomeBox1,
    HomeBox2,
    HomeBox4,
    HomeBox5,
    HomeBox8,
    HomeBox3,
    HomeBox6,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.home {
  // height: 100vh;
}
</style>
